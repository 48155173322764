

.memb-container {
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: left;
    padding: 0 40px;
    text-align: left;
}

.memb-card {
    background-color: #1e1f22;
    width: 300px;
    height: 550px;
    overflow: hidden;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
}

.memb-card img {
    -webkit-filter: grayscale(1); /* Webkit */
    filter: gray; /* IE6-9 */
    filter: grayscale(1); /* W3C */
}

.memb-card h5 {
    font-size: 16px;
}
.memb-card h4 {
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: bold;
}

.memb-card .title-container {
    padding: 10px 10px;
}

.memb-card .title-container .date-container span {
    font-size: 20px;
    font-weight: bold;
}
.memb-card .title-container .date-container {    
    margin-bottom: 10px;
}


@media (max-width: 500px){
    .memb-container {
        padding: 0;
    }
}

.btn:focus {
    border: none !important;
    outline: transparent !important;
    box-shadow: none !important;
}

.btn:active {
    border: none !important;
    outline: transparent !important;
    box-shadow: none !important;
}
