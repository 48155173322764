.h1-title {
    text-align: left;
}


@media (min-width: 1920px){
    #services {
        max-width: 90%;
        margin: auto;
    }
}

@media (min-width: 2300px){
    #services {
        max-width: 80%;
        margin: auto;
    }
}

@media (min-width: 2500px){
    #services {
        max-width: 70%;
        margin: auto;
    }
}
@media (min-width: 2700px){
    #services {
        max-width: 60%;
        margin: auto;
    }
}
#services .row {
    max-width: 100%;
}

#services .container{
    width: 100%;
    max-width: 100vw;
    margin: 0;
}

.articles-container {
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: left;
    padding: 0 40px;
}

@media (max-width: 1199px){
    #services .row {
        margin-right: 0;
        margin-left: 0;
    }
    
.articles-container {
    justify-content: center;
}
}


.high-flex {
    display: flex;
}
.searchbar-input {
    width: 40%;
}

.article {    
    background-color: white;
    width: 450px;
    height: 450px;
    overflow: hidden;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
}

.article .date-container {
    text-align: right;
    padding: 20px;
}

.article .date-container span {
    color: rgb(124, 252, 0);
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
}
.article .title-container {
    position: relative;
    top: 40%;
    padding: 15px 20px 60px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
}
.article h4 {
    color: #9EC7D9;
    text-align: left;
}
.article h5 {
    color: white;
    text-align: left;
    font-size: medium;
}

@media (min-width: 860px){
    .article{
        width: 32%;
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media (max-width: 1090px){
    .article{
        height: 500px;
    }
}


@media (max-width: 501px) {
    .article .title-container {
        top: 50%;
        padding: 15px 20px 80px 20px;
    }
    .article {    
        width: 550px;
        height: 400px;
        margin-left: 0;
        margin-right: 0;
    }
    .articles-container{
        padding: 0;
    }
    .high-flex {
        display: block;
    }
    .searchbar-input {
        width: 100%;
    }
}


@media (max-width: 450px) {
    .article .title-container {
        top: 28%;
        padding: 15px 20px 150px 20px;
    }
    .article {    
        width: 550px;
        height: 400px;
    }
}


.article-link{
    position: absolute;
    left: 0;
    right: 0;
    color: white;
    font-size: 32px;
    opacity: 0;
    transition: 0.5s;
    text-decoration: none;
    margin-top: 150px;
}



.article:hover .article-link{
    opacity: 1;
    transition: 0.5s;
    color: white;
    text-decoration: none;
    z-index: 15;
}