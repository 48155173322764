.pc-first-h4 {
    background-color: #60dfcd;
    background-image: linear-gradient(315deg, #60dfcd 0%, #1e9afe 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pc-first-font {
    font-size:26px !important;
}


.pc-first-ul {
    color: white;
}

.pc-first-ul a {
    color: white;
}