html.hc-nav-yscroll {
    overflow-y: scroll
}

@media (min-width: 1200px) {
    .control-width {
        display: none;
    }
}

@media (max-width: 1200px){
    .control-width2 {
        display: none;
    }
}


body.hc-nav-open {
    overflow: visible;
    position: fixed;
    width: 100%;
    min-height: 100%
}

.hc-offcanvas-nav {
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 9999;
    text-align: left;
}



@keyframes showNav {
    from {
        opacity: 0;
        margin-right: -280px;
    }
    to{
        opacity: 1;
    }
}


@keyframes showText {
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.show-text {
    animation: showText 1s;
    animation-fill-mode: forwards;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(0vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }



@keyframes showNav2 {
    from {
        opacity: 1;
        margin-right: 0;
    }
    to{
        opacity: 1;
        margin-right: 20px;
    }

}



.hc-offcanvas-nav.is-ios * {
    cursor: pointer !important
}

.hc-offcanvas-nav .nav-container {
    position: fixed;
    z-index: 9998;
    top: 0;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    transition: transform .4s ease;
    width: 280px;
    animation: showNav 0.5s;
    animation-fill-mode: forwards;

}




.hc-offcanvas-nav .nav-container2 {
    position: fixed;
    z-index: 9998;
    top: 0;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    transition: transform .4s ease;
    width: 280px;
    animation: showNav2 0.5s;
    animation-fill-mode: forwards;

}

@keyframes hideNav {
    from {
        opacity: 1;
    }
    to{
        opacity: 0;
        margin-right: -280px;
    }
}

.hc-offcanvas-nav .nav-container-effect {
    animation: hideNav 0.5s;
    animation-fill-mode: forwards;

} 

.hc-offcanvas-nav .nav-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -ms-scroll-chaining: none;
    overscroll-behavior: none
}

.hc-offcanvas-nav .nav-content {
    height: 100%
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content {
    overflow: scroll;
    overflow-x: visible;
    overflow-y: auto;
    box-sizing: border-box
}

.hc-offcanvas-nav ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.hc-offcanvas-nav li {
    position: relative;
    display: block
}

.hc-offcanvas-nav li.level-open>.nav-wrapper {
    visibility: visible
}

.hc-offcanvas-nav li:not(.custom-content) a {
    position: relative;
    display: block;
    box-sizing: border-box;
    cursor: pointer
}

.hc-offcanvas-nav li:not(.custom-content) a[disabled] {
    cursor: not-allowed
}

.hc-offcanvas-nav li:not(.custom-content) a,
.hc-offcanvas-nav li:not(.custom-content) a:hover {
    text-decoration: none
}

.hc-offcanvas-nav input[type="checkbox"] {
    display: none
}

.hc-offcanvas-nav label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer
}

.hc-offcanvas-nav .nav-item-wrapper {
    position: relative
}

.hc-offcanvas-nav .nav-item-link {
    position: relative;
    display: block;
    box-sizing: border-box
}

.hc-offcanvas-nav:not(.user-is-tabbing) .nav-close-button:focus,
.hc-offcanvas-nav:not(.user-is-tabbing) .nav-item-wrapper a:focus {
    outline: none
}

.hc-offcanvas-nav .nav-close:focus,
.hc-offcanvas-nav .nav-next:focus,
.hc-offcanvas-nav .nav-back:focus {
    z-index: 10
}

.hc-offcanvas-nav.disable-body::after,
.hc-offcanvas-nav .nav-wrapper::after {
    content: '';
    z-index: 9990;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease .4s, opacity .4s ease
}

.hc-offcanvas-nav.disable-body::after {
    position: fixed
}

.hc-offcanvas-nav .nav-wrapper::after {
    position: absolute
}

.hc-offcanvas-nav.disable-body.nav-open::after,
.hc-offcanvas-nav .sub-level-open::after {
    visibility: visible;
    opacity: 1;
    transition-delay: .05s
}

.hc-offcanvas-nav:not(.nav-open)::after {
    pointer-events: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper::after {
    display: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0 {
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0>.nav-content {
    overflow: scroll;
    overflow-x: visible;
    overflow-y: auto;
    box-sizing: border-box;
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper {
    min-width: 0;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: height 0s ease .4s
}

.hc-offcanvas-nav.nav-levels-expand .level-open>.nav-wrapper {
    max-height: none;
    overflow: visible;
    visibility: visible
}

.hc-offcanvas-nav.nav-levels-overlap .nav-content {
    overflow: scroll;
    overflow-x: visible;
    overflow-y: auto;
    box-sizing: border-box;
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-overlap .nav-wrapper {
    max-height: 100vh
}

.hc-offcanvas-nav.nav-levels-overlap ul .nav-wrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100%;
    visibility: hidden;
    transition: visibility 0s ease .4s, transform .4s ease
}

.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {
    position: static
}

.hc-offcanvas-nav.nav-levels-overlap ul li.level-open>.nav-wrapper {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: transform .4s ease
}

.hc-offcanvas-nav.nav-position-left {
    left: 0
}

.hc-offcanvas-nav.nav-position-left .nav-container {
    left: 0
}

.hc-offcanvas-nav.nav-position-left.nav-levels-overlap li .nav-wrapper {
    left: 0;
    transform: translate3d(-100%, 0, 0)
}

.hc-offcanvas-nav.nav-position-right {
    right: 0
}

.hc-offcanvas-nav.nav-position-right .nav-container {
    right: 0
}

.hc-offcanvas-nav.nav-position-right.nav-levels-overlap li .nav-wrapper {
    right: 0;
    transform: translate3d(100%, 0, 0)
}

.hc-offcanvas-nav.nav-position-top {
    top: 0
}

.hc-offcanvas-nav.nav-position-top .nav-container {
    top: 0;
    width: 100%
}

.hc-offcanvas-nav.nav-position-top.nav-levels-overlap li .nav-wrapper {
    left: 0;
    transform: translate3d(0, -100%, 0)
}

.hc-offcanvas-nav.nav-position-bottom {
    top: auto;
    bottom: 0
}

.hc-offcanvas-nav.nav-position-bottom .nav-container {
    top: auto;
    bottom: 0;
    width: 100%
}

.hc-offcanvas-nav.nav-position-bottom.nav-levels-overlap li .nav-wrapper {
    left: 0;
    transform: translate3d(0, 100%, 0)
}

.hc-offcanvas-nav.nav-open[class*='hc-nav-'] div.nav-container {
    transform: translate3d(0, 0, 0)
}

.hc-offcanvas-nav.rtl {
    text-align: right;
    direction: rtl
}

.hc-nav-trigger {
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top: 20px;
    z-index: 9980;
    width: 30px;
    min-height: 24px
}

.hc-nav-trigger span {
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 50% 50%
}

.hc-nav-trigger span,
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
    display: block;
    position: absolute;
    left: 0;
    height: 4px;
    background: #34495E;
    transition: all .2s ease
}

.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
    content: '';
    width: 100%
}

.hc-nav-trigger span::before {
    top: -10px
}

.hc-nav-trigger span::after {
    bottom: -10px
}

.hc-nav-trigger.toggle-open span {
    background: rgba(0, 0, 0, 0);
    transform: rotate(45deg)
}

.hc-nav-trigger.toggle-open span::before {
    transform: translate3d(0, 10px, 0)
}

.hc-nav-trigger.toggle-open span::after {
    transform: rotate(-90deg) translate3d(10px, 0, 0)
}

.hc-offcanvas-nav {
    font-family: sans-serif
}

.hc-offcanvas-nav::after,
.hc-offcanvas-nav .nav-wrapper::after {
    background: rgba(0, 0, 0, 0.3)
}

.hc-offcanvas-nav .nav-container,
.hc-offcanvas-nav .nav-wrapper,
.hc-offcanvas-nav ul {
    background: #336ca6
}

.hc-offcanvas-nav .nav-content h2,
.hc-offcanvas-nav .nav-content h3,
.hc-offcanvas-nav .nav-content h4,
.hc-offcanvas-nav .nav-content h5,
.hc-offcanvas-nav .nav-content h6 {
    font-size: 19px;
    font-weight: normal;
    padding: 20px 17px;
    color: #1b3958
}

.hc-offcanvas-nav .nav-content>h2:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content>h3:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content>h4:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content>h5:not(.nav-title):not(.level-title),
.hc-offcanvas-nav .nav-content>h6:not(.nav-title):not(.level-title) {
    font-size: 16px;
    padding: 15px 17px;
    background: #336ca6
}

.hc-offcanvas-nav .nav-item-link,
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav .nav-back a {
    padding: 14px 17px;
    font-size: 14px;
    color: #fff;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #2c5d8f;
    transition: background .1s ease
}

.hc-offcanvas-nav .nav-item-link:focus,
.hc-offcanvas-nav .nav-item-link:focus-within,
.hc-offcanvas-nav li.nav-close a:focus,
.hc-offcanvas-nav li.nav-close a:focus-within,
.hc-offcanvas-nav .nav-back a:focus,
.hc-offcanvas-nav .nav-back a:focus-within {
    z-index: 10
}

.hc-offcanvas-nav .nav-item-link[disabled],
.hc-offcanvas-nav li.nav-close a[disabled],
.hc-offcanvas-nav .nav-back a[disabled] {
    color: rgba(255, 255, 255, 0.5)
}

.hc-offcanvas-nav:not(.touch-device) li:not(.nav-item-custom) a:not([disabled]):hover {
    background: #31679e
}

.hc-offcanvas-nav .nav-custom-content {
    padding: 14px 17px;
    font-size: 14px;
    border-bottom: 1px solid #2c5d8f
}

.hc-offcanvas-nav .nav-highlight {
    background: #2e6296
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul:first-of-type>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link {
    border-top: 1px solid #2c5d8f
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul:first-of-type>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link+a {
    border-top: 1px solid #2c5d8f
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul:not(:last-child) {
    border-bottom: 2px solid #2c5d8f
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul+h2,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul+h3,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul+h4,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul+h5,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>ul+h6 {
    margin-top: -2px
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content>h2+ul>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>h3+ul>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>h4+ul>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>h5+ul>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link,
.hc-offcanvas-nav .nav-wrapper-0>.nav-content>h6+ul>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link {
    border-top: 1px solid #2c5d8f
}

.hc-offcanvas-nav li {
    color: #fff
}

.hc-offcanvas-nav li.nav-parent .nav-item-link:last-child {
    padding-right: 58px
}

.hc-offcanvas-nav li.nav-parent .nav-item-link:not(:last-child) {
    margin-right: 45px
}

.hc-offcanvas-nav .nav-close-button span,
.hc-offcanvas-nav .nav-parent .nav-next,
.hc-offcanvas-nav .nav-back span {
    width: 45px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    cursor: pointer;
    transition: background .1s ease
}

.hc-offcanvas-nav .nav-close-button {
    position: relative;
    display: block
}

.hc-offcanvas-nav .nav-close-button span::before,
.hc-offcanvas-nav .nav-close-button span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff
}

.hc-offcanvas-nav .nav-close-button span::before {
    margin-left: -9px;
    transform: rotate(135deg)
}

.hc-offcanvas-nav .nav-close-button span::after {
    transform: rotate(-45deg)
}

.hc-offcanvas-nav .nav-content>.nav-close {
    position: relative;
    z-index: 2
}

.hc-offcanvas-nav .nav-content>.nav-close a {
    font-size: 14px;
    color: #fff;
    background: rgba(0, 0, 0, 0);
    z-index: 1;
    text-decoration: none;
    box-sizing: border-box
}

.hc-offcanvas-nav .nav-content>.nav-close a:not(.has-label) {
    height: 50px
}

.hc-offcanvas-nav .nav-content>.nav-close a.has-label {
    padding: 14px 17px;
    border-top: 1px solid #2c5d8f
}

.hc-offcanvas-nav .nav-content>.nav-close a:hover {
    background: #31679e;
    border-left: 1px solid #2c5d8f
}

.hc-offcanvas-nav .nav-content>.nav-close.has-label+ul {
    margin-top: -1px
}

.hc-offcanvas-nav .nav-title.followed-empty-close {
    padding-right: 55px
}

.hc-offcanvas-nav .nav-title+.nav-close a:not(.has-label) {
    position: absolute;
    width: 45px;
    height: 66px;
    line-height: 66px;
    top: -66px;
    right: 0
}

.hc-offcanvas-nav li.nav-close a:not(.has-label) {
    height: 49px
}

.hc-offcanvas-nav .nav-content>.nav-close:first-child a,
.hc-offcanvas-nav .nav-title+.nav-close a.has-label,
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav .nav-back a {
    background: #2f649a;
    border-top: 1px solid #2b5a8b;
    border-bottom: 1px solid #2b5a8b
}

.hc-offcanvas-nav .nav-content>.nav-close:first-child a:hover,
.hc-offcanvas-nav .nav-title+.nav-close a.has-label:hover,
.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav .nav-back a:hover {
    background: #2b5c8d
}

.hc-offcanvas-nav li.nav-close:not(:first-child) a,
.hc-offcanvas-nav .nav-back:not(:first-child) a {
    margin-top: -1px
}

.hc-offcanvas-nav a.nav-next {
    border-left: 1px solid #2c5d8f;
    border-bottom: 1px solid #2c5d8f
}

.hc-offcanvas-nav .nav-next span::before,
.hc-offcanvas-nav .nav-back span::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-left: -2px;
    box-sizing: border-box;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform-origin: center
}

.hc-offcanvas-nav .nav-next span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
}

.hc-offcanvas-nav .nav-next span::before {
    transform: translate(-50%, -50%) rotate(135deg)
}

.hc-offcanvas-nav .nav-back span::before {
    margin-left: 2px;
    transform: translate(-50%, -50%) rotate(-45deg)
}

.hc-offcanvas-nav.nav-position-left.nav-open.nav-levels-overlap .nav-wrapper {
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-right.nav-open.nav-levels-overlap .nav-wrapper {
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-right .nav-next span::before {
    margin-left: 2px;
    transform: translate(-50%, -50%) rotate(-45deg)
}

.hc-offcanvas-nav.nav-position-right li.nav-back span::before {
    margin-left: -2px;
    transform: translate(-50%, -50%) rotate(135deg)
}

.hc-offcanvas-nav.nav-position-top.nav-open .nav-wrapper {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-top .nav-next span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-position-top li.nav-back span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-position-bottom.nav-open .nav-wrapper {
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-bottom .nav-next span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-position-bottom li.nav-back span::before {
    margin-left: 0;
    margin-right: -2px;
    transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper,
.hc-offcanvas-nav.nav-levels-none ul .nav-wrapper {
    box-shadow: none;
    background: transparent
}

.hc-offcanvas-nav.nav-levels-expand li.level-open,
.hc-offcanvas-nav.nav-levels-none li.level-open {
    background: #2e6296
}

.hc-offcanvas-nav.nav-levels-expand li.level-open>.nav-item-wrapper>a,
.hc-offcanvas-nav.nav-levels-none li.level-open>.nav-item-wrapper>a {
    border-bottom: 1px solid #295887
}

.hc-offcanvas-nav.nav-levels-expand li.level-open>.nav-item-wrapper>a:hover,
.hc-offcanvas-nav.nav-levels-none li.level-open>.nav-item-wrapper>a:hover {
    background: #2f649a
}

.hc-offcanvas-nav.nav-levels-expand li.level-open>.nav-item-wrapper>.nav-next span::before,
.hc-offcanvas-nav.nav-levels-expand li.level-open>.nav-item-wrapper>a>.nav-next span::before,
.hc-offcanvas-nav.nav-levels-none li.level-open>.nav-item-wrapper>.nav-next span::before,
.hc-offcanvas-nav.nav-levels-none li.level-open>.nav-item-wrapper>a>.nav-next span::before {
    margin-top: -2px;
    transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-1 .nav-item-link,
.hc-offcanvas-nav.nav-levels-none .nav-wrapper-1 .nav-item-link {
    padding-left: calc(17px + 20px * 1)
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-2 .nav-item-link,
.hc-offcanvas-nav.nav-levels-none .nav-wrapper-2 .nav-item-link {
    padding-left: calc(17px + 20px * 2)
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-3 .nav-item-link,
.hc-offcanvas-nav.nav-levels-none .nav-wrapper-3 .nav-item-link {
    padding-left: calc(17px + 20px * 3)
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-4 .nav-item-link,
.hc-offcanvas-nav.nav-levels-none .nav-wrapper-4 .nav-item-link {
    padding-left: calc(17px + 20px * 4)
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper-5 .nav-item-link,
.hc-offcanvas-nav.nav-levels-none .nav-wrapper-5 .nav-item-link {
    padding-left: calc(17px + 20px * 5)
}

.hc-offcanvas-nav.rtl a.nav-next {
    border-left: none;
    border-right: 1px solid #2c5d8f
}

.hc-offcanvas-nav.rtl .nav-title+.nav-close a:not(.has-label),
.hc-offcanvas-nav.rtl .nav-close-button span,
.hc-offcanvas-nav.rtl .nav-next,
.hc-offcanvas-nav.rtl .nav-back span {
    left: 0;
    right: auto
}

.hc-offcanvas-nav.rtl li.nav-parent .nav-item-link:last-child {
    padding-left: 58px;
    padding-right: 17px
}

.hc-offcanvas-nav.rtl li.nav-parent .nav-item-link:not(:last-child) {
    margin-left: 45px;
    margin-right: 0
}

.hc-offcanvas-nav.rtl .nav-wrapper-1 li.nav-item .nav-item-link {
    padding-right: calc(17px + 20px * 1)
}

.hc-offcanvas-nav.rtl .nav-wrapper-2 li.nav-item .nav-item-link {
    padding-right: calc(17px + 20px * 2)
}

.hc-offcanvas-nav.rtl .nav-wrapper-3 li.nav-item .nav-item-link {
    padding-right: calc(17px + 20px * 3)
}

.hc-offcanvas-nav.rtl .nav-wrapper-4 li.nav-item .nav-item-link {
    padding-right: calc(17px + 20px * 4)
}

.hc-offcanvas-nav.rtl .nav-wrapper-5 li.nav-item .nav-item-link {
    padding-right: calc(17px + 20px * 5)
}