.hc-first-section {
    background-image: url(assets/images/nebula.png);
}

.hc-second-section {
    background-color: black;
}

.hc-thirst-section {
    background-image: url(assets/images/world-space.png);
}

#reload_href {
    color: inherit !important;
}

#canv {
    border: 1px solid rgba(167, 186, 196, .75);
    max-width: 250px;
}


.hc-four-section {
    background-color: black;
    padding-bottom: 10px !important;
}

.hc-first-div {
    opacity: 0;
}

.hc-second-div {
    max-width: 976px;
    margin: 0 auto;
}

.hc-first-i {
    color: #1ad1ff;
}

.hc-second-i {
    color: green;
}


.hc-thirst-i {
    color: yellow;
}

.hc-four-i {
    color: #1ad1ff;
}

.hc-five-i {
    font-size: 21px;
}

.hc-first-strong {
    color: white;
}

.hc-first-a {
    color: #1e9afe;
}


.hc-second-strong {
    color: white;
}



.hc-first-img {
    width: 100%;
    height: auto;
}

.hc-second-img {
    height: 70px;
    width: auto;
}

.hc-thirst-img {
    height: 70px;
    width: auto;
    filter: invert(1);
}

.hc-first-h1 {
    visibility: visible;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 0s;
}

canvas {
    width: 100%;
    height: 100%;
    position: static !important;
    background-image: url('');
    background-size: cover;
    background-repeat: no-repeat;
}

.body-particles {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-attachment: inherit;
}



.animated-text-container {
    display: flex;
    vertical-align: middle;
    min-height: 4ch;
}

.animated-text-container div {
    margin: auto;
}

.phrase1 {
    margin: auto;
    width: 15ch;
    display: block;
    animation: typing 5s steps(17), .5s infinite step-end alternate;
    animation-fill-mode: forwards;
    overflow: hidden;
    border-right: 4px solid transparent;
    white-space: nowrap;
}

@keyframes typing {
    0% {
        width: 0;
    }

    60% {
        width: 17ch;
    }

    80% {
        width: 17ch;
    }

    100% {
        width: 0;
    }
}



.phrase2 {
    margin: auto;
    width: 24ch;
    display: block;
    animation: typing2 6s steps(23), .5s infinite step-end alternate;
    animation-fill-mode: forwards;
    overflow: hidden;
    border-right: 4px solid transparent;
    white-space: nowrap;
}

@keyframes typing2 {
    0% {
        width: 0;
    }

    60% {
        width: 24ch;
    }

    80% {
        width: 24ch;
    }

    100% {
        width: 0;
    }
}

.phrase3 {
    margin: auto;
    width: 0ch;
    display: block;
    animation: typing3 7s steps(16), .5s infinite step-end alternate;
    animation-fill-mode: forwards;
    overflow: hidden;
    border-right: 4px solid transparent;
    white-space: nowrap;
}

.phrase3-2 {
    margin: auto;
    width: 0ch;
    display: block;
    animation: typing3-2 4s steps(18), .5s infinite step-end alternate;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    overflow: hidden;
    border-right: 4px solid transparent;
    white-space: nowrap;
}

@keyframes typing3 {
    0% {
        width: 0;
    }

    40% {
        width: 16ch;
    }

    80% {
        width: 16ch;
    }

    100% {
        width: 0;
    }
}




@keyframes typing3-2 {
    0% {
        width: 0;
    }

    60% {
        width: 18ch;
    }

    80% {
        width: 18ch;
    }

    100% {
        width: 0;
    }
}


.phrase4 {
    margin: auto;
    width: 20ch;
    display: block;
    animation: typing4 5s steps(20), .5s infinite step-end alternate;
    animation-fill-mode: forwards;
    overflow: hidden;
    border-right: 4px solid transparent;
    white-space: nowrap;
}

@keyframes typing4 {
    0% {
        width: 0;
    }

    60% {
        width: 20ch;
    }

    80% {
        width: 20ch;
    }

    100% {
        width: 0;
    }
}

.hold-wrap {
    flex-wrap: nowrap;
}


@keyframes blink2 {
    50% {
        border-color: white;
    }
}

.node-cards-container {
    max-width: 900px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.node-card-container {
    margin: auto;
    margin-top: 50px;
    border: 1px solid #ddd;
    width: 30%;
    min-height: 500px;
    padding-top: 20px;
    text-align: justify;
    position: relative;
    background-color: white;
    border-radius: 2px;
}

.faucet-cont{
    background: none;
    padding: 20px;
}

.node-card-divs img:hover {
    filter: brightness(1.04);
}


.node-card-divs a {
    color: #1ad1ff;
}

.node-card-divs strong {
    color: #1ad1ff;
}

.node-card-divs a:hover {
    color: #337ab7;
}

.join-as-button {
    margin-top: 15px;
    color: #888;
    background-color: #eee;
    border: none;
    border-radius: 0;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 10px 0 10px 0;
    font-weight: 700;
    position: absolute !important;
    bottom: 0;
}

.join-as-button:hover {
    background-color: #ccc !important;
    background: #ccc !important;
}

.tran-d {
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
    min-height: 200px;
}

.tran-no {
    width: 50%;
    margin: auto;
    margin-bottom: 50px;
    min-height: 200px;
}


.node-card-divs {
    margin: 0 20px 0 20px;
}

@media (max-width: 680px) {
    .hold-wrap {
        flex-wrap: wrap;
    }
}



.tr-primary {}

.variable-tr {}

.variable-td {
    display: revert;
    width: 100%;
}

.variable-name {
    display: none;
}

.variable-value {
    display: block;
    width: 100%;
}


.variable-tx {
    width: 80%;
}


.network-widget-container {
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
}

.peers-table {
    display: flex;
    width: 40%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.widget-container {
    padding: 10px;
}

.mapchart-container {
    width: 60%;
}

.widget-data-container {
    display: initial;
}

.widget-data-responsive {
    display: none;
}

@media (max-width: 500px) {

    .animated-text-container span {
        font-size: 30px;
    }

    .node-cards-container {
        display: block;
    }

    .node-card-container {
        width: 90%;
        display: block;
    }

    .node-card-divs div {
        padding: 10px;
    }


    .join-as-button {
        position: relative !important;
    }

    .tran-d {
        width: 90%;
        min-height: 200px;
    }

    .tran-no {
        width: 90%;
        min-height: 200px;
    }

    .node-card-divs {
        width: 100%;
        margin: 0;
    }



    .tb-primary {
        border: none;
    }


    .tr-primary {
        display: none;
    }

    .variable-tr {
        display: block;
        margin-bottom: 35px;
    }

    .variable-td {
        display: flex;
        width: 100%;
        text-align: center;
    }

    .variable-tx {
        width: 100%;
    }

    .variable-name {
        display: inline-block;
        width: 50%;
    }

    .variable-value {
        display: inline-block;
        width: 50%;
        text-align: center;
    }


    .network-widget-container {
        display: block;
    }

    .peers-table {
        width: 100%;
        display: block;
        text-align: center;
    }

    .widget-container {
        border-bottom: 1px solid #333746;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    .mapchart-container {
        width: 100%;
        margin-bottom: 40px;
    }

    .countries-flags-container {
        justify-content: center;
    }

    .widget-data-container {
        display: none;
    }

    .widget-data-responsive {
        display: initial;
    }



}

@media (max-width: 385px) {
    .animated-text-container span {
        font-size: 26px;
    }

    .hc-second-div h2 {
        font-size: 16px;
    }
}


.mobile-centered {
    text-align: left;
}


@media (max-width: 500px) {
    .mobile-centered {
        text-align: center !important;
    }
}