.map-container {
    height: 100vh;
    width: 100vw;
	position: absolute;
	top: 0;
	bottom: 0;
    }
    
.map-container table,
.map-container th,
.map-container td {
    border: none;
    padding: 0px;
    word-break: break-all;
}
    .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
    }

    
	#bottom_nav {
		border-radius: 10px 10px 0px 0px;
		background-color: #0e1621;
		border-color:#6F8FAF;
		left:3px;
		height: 130px;  
		width: 150px;
		position:fixed;	 
		bottom:0px;
		text-align:left;
		margin: 0px auto;
		z-index:9999999999999999;
		border-width:1px;
		border-style:solid;
	}	

    
	.mapboxgl-ctrl-bottom-right {align:right; margin-bottom:20px;}
	.mapboxgl-ctrl-attrib-inner {display:none;}
	.mapboxgl-ctrl-logo {display:none;}
	
	.marker {
		background-image: url(assets/images/marker.gif);
		background-size: cover;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		cursor: pointer;
		
	}
	
	.mapboxgl-popup-content {
		border-radius: 10px 10px 10px 10px;	
		background-color: #0e1621 !important;
		width: 300px;
        text-align: left;
        font-family: 'Open Sans', sans-serif;
		z-index:9999999999999999 !important;
		border-width:1px;
		border-style:solid;
		border-color:#6F8FAF;
      }
	  
	#bottom_nav {
		border-radius: 10px 10px 0px 0px;
		background-color: #0e1621;
		border-color:#6F8FAF;
		left:3px;
		height: 130px;  
		width: 150px;
		position:fixed;	 
		bottom:0px;
		text-align:left;
		margin: 0px auto;
		z-index:9999999999999999;
		border-width:1px;
		border-style:solid;
	}	
