body {
    background-color: black;
    min-height: 100vh;
    
    --main-bg-color: #000;
    --secondary-bg-color: #6def4d;
    }
    
    
    
    .main-product-cards-div {
        background-color: black;
        width: 70%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
    }
    
    .card-title-container {
        max-height: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        z-index: 1000;
    }
    
    .card-title-container h2{
        background-color: var(--secondary-bg-color);    
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        z-index: 100;
    }
    
    
    .card-spec-container {
        position: relative;
        padding-top: 10px;
    }
    
    
    .card-spec-container h2{
        color: white;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        z-index: 100;
    }
    
    
    .styling-div-spec{    
        position: absolute;
        width: 230px;
        transform: rotate(-25deg) scaleY(-1);
        height: 150px;
        top: 0;
        z-index: 70;
        border-bottom: 3px solid rgb(43, 43, 43);
        display: none;        
    }
    
    
    
    
    .styling-div-spec div {    
        width: 200px;
        height: 200px;
        transform: rotate(44deg);
        position: absolute;
        right: -44%;
        top: -61%;
        border-right: 3px solid rgb(43, 43, 43);
    }
    
    
    .buy-button {
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        position: absolute;
        bottom: 20px;
    }
    
    .buy-button div {
        background-color: var(--main-bg-color);
        margin: auto;
        position: relative;
        padding: 5px 15px;
        cursor: pointer;
    }
    
    
    .buy-button div span {
        color: black;
        text-decoration: none;
        position: relative;
        z-index: 144;
        padding: 5px 10px;
    
    }
    
    .button-back {    
        position: absolute !important;
        padding: 5px 5px !important;
        width: 80%;
        top: 0;
        left: 0;
        right: 0;
        background-color: var(--secondary-bg-color) !important;    
        color: var(--secondary-bg-color);    
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -ms-transform: skew(30deg);
        -o-transform: skew(30deg);
        transform: skew(30deg);
        transition: 0.5s;
    }
    
    .buy-button div:hover .button-back {    
        width: 100%;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -ms-transform: skew(0deg);
        -o-transform: skew(0deg);
        transform: skew(0deg);
        transition: 0.5s;
    }
    
    
    .spec-content {
        color: black;
        position: relative;
        z-index: 130;
    }
    
    .spec-content ul {
        color: white;        
        list-style-type: none;
        padding: 0;
    }
    
    
    .styling-div-title{
        position: absolute;
        background-color: var(--secondary-bg-color);
        width: 230px;
        transform: rotate(25deg);
        height: 150px;
        top: 0;
        z-index: 70;
        display: none;
    }
    
    .styling-div-title div {    
        width: 200px;
        height: 200px;
        background: var(--secondary-bg-color);
        transform: rotate(45deg);
        position: absolute;
        right: -44%;
        top: -61%;
        box-shadow: inset 0 5px 0 3px var(--fourth-bg-color);
    }
    
    .products-card {
        width: 31%;
        min-height: 520px;
        background-color: var(--main-bg-color);
        text-align: center;
        color: white;
        overflow: hidden;
        position: relative;
        border-radius: 3px;
        border: solid 1px gray;
    }
    
    .product-img-container{        
    position: absolute;
    width: 100%;
    height: 200px;
    }
    

    .product-img-container img {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .products-card h2 {
        font-size: 20px;
        color: white;
        padding: 5px;
    }


    
    .price-container {
        display: flex;
        min-height: 140px;
        width: 100%;
        position: relative;
    }
    
    .price-div {
        width: 0;
        height: 0;
        border-left: 200px solid var(--secondary-bg-color);
        border-top: 100px solid transparent;
        border-bottom: 100px solid transparent;
    }
    
    .price-div div {
        background-color: var(--main-bg-color);
        height: 100%;
    }
    
    .price-container span {
        position:absolute;
        font-size: 30px;
        bottom: 80px;
        left: 25px;
        color: black;
    }
    
    
    .styling-div{
        width: 40%;
        width: 0;
        height: 0;
        border-right: 200px solid var(--secondary-bg-color);
        border-top: 100px solid transparent;
        border-bottom: 100px solid transparent;
    }
    

    @media (max-width: 500px) {
        .main-product-cards-div {
            display: block;
            width: 80%;
        }
        .products-card {
            width: 100%;
            margin-top: 30px;
        }

        .product-img-container{
            height: 250px;
        }

        .card-title-container {
            max-height: 150px;
            height: 150px;
        }

        .buy-button {
            margin-bottom: 20px;
            position: initial;
        }

    }