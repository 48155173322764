.coming-soon-container h1{
    font-size: 51px;
    color: white;
}


.coming-soon-container h4{
    font-size: 30px;
    color: white;
}

.coming-soon-container button {
    color: hsla(0,0%,100%,.8);
    margin-top: 0px;
    padding: 15px 15px;
    font-family: blinker,sans-serif;
    font-size: 21px;
    border-bottom: 5px solid hsla(0,0%,100%,.8);
    transition: 0.3s;
}

.coming-soon-container button:hover {
    color: hsla(0,0%,100%,.7);
    border-bottom: 5px solid hsla(0,0%,100%,.7);
    transition: 0.3s;
}

@media (max-width: 600px){
    .coming-soon-container h1{
        font-size: 34px;
    }
    .coming-soon-container h4{
        font-size: 24px;
    }
    .row div img{
        width: 100% !important;
    }
}

@media (max-width: 400px){
    .coming-soon-container h1{
        font-size: 24px;
    }
    .coming-soon-container h4{
        font-size: 18px;
    }
}

