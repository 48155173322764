
.more-news {
    text-align: left;
    width: 100%; 
}

.share-section {
    display: flex;
}
    .share-container.left{
        width: 50%;
        margin-top: 30px;
    }
    .share-container.right {
        width: 50%;
        margin-top: 15px;
    }


.share-div{
    width: 100%;
    min-height: 100px;
    padding-top: 5px;
    text-align: right;
}

section {
    color: #9ec7d9;
}

.button-share{
    border-color: #28a745 !important;
    transition: 0.5s;
    margin-top: 8px;
    margin-right: 5px;
}

.button-share:hover {
    transition: 0.5s;
}

.button-share-last:hover {
}

.articles-cont {
    flex-wrap: nowrap;
    padding: 0;
    justify-content: space-between;    
} 

@media (max-width: 1100px){
    .articles-cont {
        flex-wrap: wrap;
    } 

    .articles-cont .article{
        margin-left: 0 !important;
        margin-right: 0 !important;
        position: relative;
    }
    .article-content-span {
        font-size: 16px;
    }
}

.article-link {
    text-align: center;
}

@media (min-width: 1100px){
    .articles-cont .title-container {
        top: 25%;
        padding-bottom: 150px;
    }
    .article-content-span {
        font-size: 17px;
    }
    .articles-cont .article{
        margin-left: 0 ;
        margin-right: 0 ;
    }
    
}

@media (min-width: 1900px){
    .articles-cont {        
    justify-content: space-around;   
    }
    .articles-cont .title-container {
        top: 15%;
        padding-bottom: 150px;
    }    
    .article-content-span {
        font-size: 17px;
    }
}




.article-back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.0);
    transition: 0.5s;
}

.article:hover .article-back {
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.5s;
}
@media (max-width: 500px){
    .more-news {
        text-align: center;
        width: 100%; 
    }
    
    .share-section {
        display: block;
    }
    .share-container.left {
        width: 100%;
        margin-top: 30px;
        text-align: center;
    }
    .share-container.right {
        width: 100%;
        margin-top: 15px;
        text-align: center;
    }

    .share-div {
        text-align: center;
    }
    .article-content-span {        
        text-align: justify;
    }

}