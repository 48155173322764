.ap-first-h4 {
    background-color: #60dfcd;
    background-image: linear-gradient(315deg, #60dfcd 0%, #1e9afe 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


table,
th,
td {
    border: 1px solid #333746;
    padding: 10px;
    word-break: initial;
}

.ad-first-font {
    background-color: #60dfcd;
    background-image: linear-gradient(315deg, #60dfcd 0%, #1e9afe 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
